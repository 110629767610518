import { GifReader } from "omggif";

import { Energy, Seam, IntermediateImage } from "./contentAwareScale/types";

import {
  readAsDataURL,
  readAsArrayBuffer,
  loadImage,
  createCanvas,
  createCanvasFromImageData,
  getContext,
  getImageData,
  resizeLargestDimensionAbsolute,
  resize,
} from "./imageUtils";

import gifEncoder from "./gifEncoder";
import contentAwareScale from "./contentAwareScale";

export interface Progress {
  message: string;
  thingsDone: number;
  thingsTotal: number;
}

export interface Frame {
  imageData: ImageData;
  delay: number;
}

export interface Result {
  dataUrl: string;
}

export type ProgressCallback = (progress: Progress) => void;
export type ResultCallback = (result: Result) => void;

const calculateEnergy = (imageData: ImageData): Energy => {
  const getPixelValue = (x: number, y: number, offset: number) => {
    return imageData.data[y * (imageData.width * 4) + x * 4 + offset];
  };

  const getEnergy = (x: number, y: number) => {
    const left = Math.max(0, x - 1);
    const right = Math.min(x + 1, imageData.width - 1);
    const up = Math.max(0, y - 1);
    const down = Math.min(y + 1, imageData.height - 1);

    let energy = 0;

    for (let i = 0; i < 3; i++) {
      energy += Math.pow(
        getPixelValue(left, y, i) - getPixelValue(right, y, i),
        2
      );
    }

    for (let i = 0; i < 3; i++) {
      energy += Math.pow(
        getPixelValue(x, up, i) - getPixelValue(x, down, i),
        2
      );
    }

    return energy;
  };

  const data = new Uint32Array(imageData.width * imageData.height);

  for (let x = 0; x < imageData.width; x++) {
    for (let y = 0; y < imageData.height; y++) {
      data[y * imageData.width + x] = getEnergy(x, y);
    }
  }

  return {
    data,
    width: imageData.width,
    height: imageData.height,
  };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const renderEnergy = ({ data, width, height }: Energy): HTMLCanvasElement => {
  let maxEnergy = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i] > maxEnergy) {
      maxEnergy = data[i];
    }
  }

  const canvas = createCanvas(width, height);
  const context = getContext(canvas);
  const imageData = context.createImageData(width, height);

  for (let i = 0; i < data.length; i++) {
    const brightness = (data[i] * 255) / maxEnergy;
    imageData.data[i * 4] = brightness;
    imageData.data[i * 4 + 1] = brightness;
    imageData.data[i * 4 + 2] = brightness;
    imageData.data[i * 4 + 3] = 255;
  }

  context.putImageData(imageData, 0, 0);

  return canvas;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const renderSeam = (
  input: HTMLCanvasElement,
  seam: Seam
): HTMLCanvasElement => {
  const imageData = getImageData(input);

  for (let y = 0; y < seam.length; y++) {
    const x = seam[y];
    const pixelIndex = y * imageData.width * 4 + x * 4;

    imageData.data[pixelIndex + 0] = 255; // set red to 255
    imageData.data[pixelIndex + 1] = 0; // set green to 0
    imageData.data[pixelIndex + 2] = 0; // set blue to 0
    imageData.data[pixelIndex + 3] = 255; // set alpha to 255
  }

  const output = createCanvas(input.width, input.height);
  getContext(output).putImageData(imageData, 0, 0);
  return output;
};

// Take a number between 0 and 1 and sigmoid-ify it
const sigmoid = (x: number) => 0.5 * (1 + Math.sin(Math.PI * (x - 0.5)));

const carveStatic = async (
  file: File,
  minScale: number,
  numFrames: number,
  durationSeconds: number,
  onProgress: ProgressCallback,
  onResult: ResultCallback
) => {
  onProgress({ message: "Processing", thingsDone: 0, thingsTotal: numFrames });

  const dataUrl = await readAsDataURL(file);
  const image = await loadImage(dataUrl);
  const input = resizeLargestDimensionAbsolute(image, 500);
  const initialImageData = getImageData(input);

  // GIF encodes frame delay in hundredths of a second
  const delay = (100 * durationSeconds) / numFrames;

  const frames: Frame[] = [];

  let intermediateImage: IntermediateImage = {
    energy: calculateEnergy(initialImageData),
    imageData: initialImageData,
  };

  for (let frameIndex = 0; frameIndex < numFrames; frameIndex++) {
    const targetScale = 1 - (1 - minScale) * sigmoid(frameIndex / numFrames);
    const targetWidth = Math.round(input.width * targetScale);
    const targetHeight = Math.round(input.height * targetScale);

    intermediateImage = await contentAwareScale({
      input: intermediateImage,
      targetWidth,
      targetHeight,
    });

    frames.push({
      imageData: getImageData(
        resize(
          createCanvasFromImageData(intermediateImage.imageData),
          input.width,
          input.height
        )
      ),
      delay,
    });

    onProgress({
      message: "Processing",
      thingsDone: frameIndex + 1,
      thingsTotal: numFrames,
    });
  }

  for (let i = 0; i < numFrames; i++) {
    frames.push(frames[numFrames - 1 - i]);
    onProgress({
      message: "Processing",
      thingsDone: i,
      thingsTotal: numFrames,
    });
  }

  console.log("Encoding gif");
  gifEncoder.sendRequest(
    { frames, width: input.width, height: input.height },
    ({ framesEncoded }) =>
      onProgress({
        message: "Encoding",
        thingsDone: framesEncoded,
        thingsTotal: frames.length,
      }),
    ({ dataUrl }) => onResult({ dataUrl })
  );
};

const carveGif = async (
  file: File,
  minScale: number,
  onProgress: ProgressCallback,
  onResult: ResultCallback
) => {
  onProgress({ message: "Processing", thingsDone: 0, thingsTotal: 1 });

  const arrayBuffer = await readAsArrayBuffer(file);
  const view = new Uint8Array(arrayBuffer);
  const gifReader = new GifReader(view as Buffer);
  const numFrames = gifReader.numFrames();

  const frames: Frame[] = [];
  const pixels = new Uint8ClampedArray(gifReader.width * gifReader.height * 4);

  for (let frameIndex = 0; frameIndex < numFrames; frameIndex++) {
    const targetScale = 1 - ((1 - minScale) * frameIndex) / numFrames;
    const targetWidth = Math.round(gifReader.width * targetScale);
    const targetHeight = Math.round(gifReader.height * targetScale);

    gifReader.decodeAndBlitFrameRGBA(frameIndex, pixels);

    const imageData = new ImageData(pixels, gifReader.width, gifReader.height);

    const image: IntermediateImage = {
      energy: calculateEnergy(imageData),
      imageData,
    };

    const scaledImage = await contentAwareScale({
      input: image,
      targetWidth,
      targetHeight,
    });

    frames.push({
      imageData: getImageData(
        resize(
          createCanvasFromImageData(scaledImage.imageData),
          gifReader.width,
          gifReader.height
        )
      ),
      delay: gifReader.frameInfo(frameIndex).delay,
    });

    onProgress({
      message: "Processing",
      thingsDone: frameIndex + 1,
      thingsTotal: numFrames,
    });
  }

  console.log("Encoding gif");
  gifEncoder.sendRequest(
    { frames, width: gifReader.width, height: gifReader.height },
    ({ framesEncoded }) =>
      onProgress({
        message: "Encoding",
        thingsDone: framesEncoded,
        thingsTotal: frames.length,
      }),
    ({ dataUrl }) => onResult({ dataUrl })
  );
};

export { carveStatic, carveGif };
