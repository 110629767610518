const PROGRESS = "progress";
const RESPONSE = "response";

class Caller<RequestType, ProgressType, ResponseType> {
  private worker: Worker;
  private lastJobId: number;

  constructor(worker: Worker) {
    this.worker = worker;
    this.lastJobId = 0;
  }

  sendRequest(
    request: RequestType,
    onProgress: (progress: ProgressType) => void,
    onResponse: (response: ResponseType) => void
  ): void {
    const jobId = this.nextJobId();
    const handler = (event: MessageEvent) => {
      if (event.data.jobId !== jobId) {
        return;
      }

      if (event.data.type === PROGRESS) {
        onProgress(event.data.progress);
      } else if (event.data.type === RESPONSE) {
        this.worker.removeEventListener("message", handler);
        onResponse(event.data.response);
      }
    };

    this.worker.addEventListener("message", handler);
    this.worker.postMessage({ jobId, request });
  }

  private nextJobId() {
    return this.lastJobId++;
  }
}

class Callee<RequestType, ProgressType, ResponseType> {
  private ctx: Worker;

  constructor(ctx: Worker) {
    this.ctx = ctx;
  }

  addRequestHandler(callback: (jobId: number, request: RequestType) => void) {
    this.ctx.addEventListener("message", (event: MessageEvent) =>
      callback(event.data.jobId, event.data.request)
    );
  }

  sendProgress(jobId: number, progress: ProgressType) {
    this.ctx.postMessage({ jobId, type: PROGRESS, progress });
  }

  sendResponse(jobId: number, response: ResponseType) {
    this.ctx.postMessage({ jobId, type: RESPONSE, response });
  }
}

export { Caller, Callee };
