export const readAsDataURL = (input: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(input);
  });

export const readAsArrayBuffer = (input: Blob): Promise<ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(input);
  });

export const loadImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (event, source, lineno, colno, error) => reject(error);
    image.src = url;
  });

export const createCanvas = (
  width: number,
  height: number
): HTMLCanvasElement => {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  return canvas;
};

export const createCanvasFromImageData = (
  imageData: ImageData
): HTMLCanvasElement => {
  const canvas = createCanvas(imageData.width, imageData.height);
  getContext(canvas).putImageData(imageData, 0, 0);
  return canvas;
};

export const getContext = (
  canvas: HTMLCanvasElement
): CanvasRenderingContext2D => {
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Failed to get canvas context");
  }
  return context;
};

export const getImageData = (canvas: HTMLCanvasElement): ImageData =>
  getContext(canvas).getImageData(0, 0, canvas.width, canvas.height);

export const resizeLargestDimensionAbsolute = (
  image: HTMLImageElement,
  maxSize: number
): HTMLCanvasElement => {
  const largestDimension = Math.max(image.width, image.height);
  const targetSize = Math.min(maxSize, largestDimension);
  const scale = targetSize / largestDimension;
  const targetWidth = Math.round(image.width * scale);
  const targetHeight = Math.round(image.height * scale);

  return resize(image, targetWidth, targetHeight);
};

export const resize = (
  image: CanvasImageSource,
  targetWidth: number,
  targetHeight: number
): HTMLCanvasElement => {
  const canvas = createCanvas(targetWidth, targetHeight);
  const context = getContext(canvas);
  context.drawImage(image, 0, 0, targetWidth, targetHeight);
  return canvas;
};
