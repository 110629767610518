import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";

import { carveStatic, carveGif, Progress, Result } from "./carve";

import styles from "./App.module.css";

interface State {
  status: "initial" | "processing" | "done";
  progress: Progress | null;
  result: Result | null;
}

const MIN_SCALE = 0.5;
const STATIC_INPUT_NUM_FRAMES = 40;
const STATIC_INPUT_DURATION_SECONDS = 1.5;

function App() {
  const [state, setState] = useState<State>({
    status: "initial",
    progress: null,
    result: null,
  });

  const reset = (event: any) => {
    event.preventDefault();
    setState((state) => ({ ...state, status: "initial" }));
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setState((state) => ({ ...state, status: "processing", progress: null }));

    const startTime = new Date();

    const onProgress = (progress: Progress) =>
      setState((state) => ({ ...state, progress }));
    const onResult = (result: Result) => {
      console.log(
        "Carved in %s seconds",
        ((new Date().getTime() - startTime.getTime()) / 1000).toFixed(2)
      );
      setState((state) => ({ ...state, status: "done", result }));
    };

    const file = acceptedFiles[0];
    if (file.type === "image/gif") {
      carveGif(file, MIN_SCALE, onProgress, onResult);
    } else {
      carveStatic(
        file,
        MIN_SCALE,
        STATIC_INPUT_NUM_FRAMES,
        STATIC_INPUT_DURATION_SECONDS,
        onProgress,
        onResult
      );
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={classnames(styles.container)}>
      <h1>Carver</h1>

      <div className={styles.ui}>
        {state.status === "initial" && (
          <div className={styles.initial}>
            <p>Choose a gif or still image to start.</p>
            <div
              className={classnames(styles.fileChooser, {
                [styles.isDragActive]: isDragActive,
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              Choose image
            </div>
          </div>
        )}
        {state.status === "processing" && state.progress && (
          <div className={styles.progress}>
            <div className={styles.progressMessage}>
              {state.progress.message}
            </div>
            <div className={styles.progressBar}>
              <div
                className={styles.progressBarFilled}
                style={{
                  width: asPercentage(
                    state.progress.thingsDone / state.progress.thingsTotal
                  ),
                }}
              >
                &nbsp;
              </div>
            </div>
          </div>
        )}
        {state.status === "done" && state.result && (
          <div className={styles.result}>
            <img alt="output" src={state.result.dataUrl} />
            <div className={styles.actions}>
              <span>
                <a
                  href={state.result.dataUrl}
                  download="carved.gif"
                  onClick={(e) => e.stopPropagation()}
                >
                  Download
                </a>
              </span>
              <span>
                <a href="#" onClick={reset}>
                  Do another one
                </a>
              </span>
            </div>
          </div>
        )}
      </div>

      <div className={styles.footer}>
        by{" "}
        <a href="https://twitter.com/AanandPrasad" target="_blank">
          Aanand
        </a>
      </div>
    </div>
  );
}

const asPercentage = (fraction: number): string =>
  (fraction * 100).toFixed(2) + "%";

export default App;
