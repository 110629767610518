import { Caller } from "../callInWorker";
import { Request, Progress, Response, IntermediateImage } from "./types";

const caller = new Caller<Request, Progress, Response>(
  new Worker("./scale.worker.ts", { type: "module" })
);

export default (request: Request): Promise<IntermediateImage> =>
  new Promise((resolve) => {
    caller.sendRequest(
      request,
      (_progress) => {},
      ({ image }) => resolve(image)
    );
  });
